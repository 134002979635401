import { Component, OnInit } from '@angular/core';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {BannedProcessModel, ProcessModel} from '../../../../types/process.model';
import {DeleteDialogComponent} from '../../../../shared/components/delete-dialog/delete-dialog.component';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ValidationMessageService} from '../../../../shared/services/validation-message-handle';
import {ToastrService} from 'ngx-toastr';
import {ProcessService} from '../../../../services/process.service';
import {AddProcessInBlacklistComponent} from '../add-process-in-blacklist/add-process-in-blacklist.component';
import {UsersAddEditComponent} from '../../../users/users-add-edit/users-add-edit.component';
import {MessageDialogComponent} from '../../../../shared/components/message-dialog/message-dialog.component';


@Component({
  selector: 'app-process-list-action-template',
  templateUrl: './process-list-action-template.component.html',
  styleUrls: ['./process-list-action-template.component.css']
})
export class ProcessListActionTemplateComponent implements OnInit {

  public processModel: ProcessModel;
  public loading = false;
  public table: GridComponent;

  constructor(
      private processService: ProcessService,
      private dialog: MatDialog,
      private route: ActivatedRoute,
      private _formBuilder: FormBuilder,
      private validationMessageService: ValidationMessageService,
      private toastr: ToastrService,
      private router: Router,
  ) {
  }

  ngOnInit(): void
  {

  }

  setParams(process: ProcessModel): void
  {
    this.processModel = new ProcessModel().map(process);
  }

  addToBan(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'dialog-class';
    dialogConfig.width = '80vw';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    const dialog = this.dialog.open(AddProcessInBlacklistComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      data: {
        process: this.processModel,
        message: 'Esti sigur ca vrei sa adaugi toate procesele cu denumire ' + this.processModel.name + ' in blacklist?',
      }
    });

    dialog.afterClosed().subscribe(result => {

      if (result){

        const bannedProcess = new BannedProcessModel().map(result);

        this.loading = true;
        this.processService.banProcess(bannedProcess)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
              this.toastr.success('Succes!', 'Procesul a fost adaugat in blacklist!');
              this.table.refresh();
              dialog.close();
            }, err => {
              if (err instanceof HttpErrorResponse) {
                this.toastr.error(err.message);
              }
            });
      }
    });

  }

  unban(): void {

    const dialog = this.dialog.open(MessageDialogComponent, {
      autoFocus: true,
      data: {
        title: this.processModel.name,
        text: 'Esti sigur ca vrei sa scoti din blacklist procesul: ' + this.processModel.name,
      }
    });

    dialog.afterClosed().subscribe(result => {

      if (result === true) {
        this.loading = true;
        this.processService.unbanProcess(this.processModel.name)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
              this.toastr.success('Succes!', 'Procesul a fost scos din blacklist!');
              this.table.refresh();
              dialog.close();
            }, err => {
              if (err instanceof HttpErrorResponse) {
                this.toastr.error(err.message);
              }
            });
      }

    });
  }

  removeFromWhiteList(): void {

    const dialog = this.dialog.open(MessageDialogComponent, {
      autoFocus: true,
      data: {
        title: this.processModel.name,
        text: 'Esti sigur ca vrei sa scoti din whitelist procesul: ' + this.processModel.name,
      }
    });

    dialog.afterClosed().subscribe(result => {

      if (result === true) {
        this.loading = true;
        this.processService.removeFromWhitelist(this.processModel.name)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
              this.toastr.success('Succes!', 'Procesul a fost scos din whitelist!');
              this.table.refresh();
              dialog.close();
            }, err => {
              if (err instanceof HttpErrorResponse) {
                this.toastr.error(err.message);
              }
            });
      }

    });
  }

  addToWhite(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'dialog-class';
    dialogConfig.width = '80vw';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    const dialog = this.dialog.open(AddProcessInBlacklistComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      data: {
        process: this.processModel,
        message: 'Esti sigur ca vrei sa adaugi toate procesele cu denumire ' + this.processModel.name + ' in whitelist?',
      }
    });

    dialog.afterClosed().subscribe(result => {

      if (result){

        const bannedProcess = new BannedProcessModel().map(result);

        this.loading = true;
        this.processService.putToWhiteList(bannedProcess)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
              this.toastr.success('Succes!', 'Procesul a fost adaugat in blacklist!');
              this.table.refresh();
              dialog.close();
            }, err => {
              if (err instanceof HttpErrorResponse) {
                this.toastr.error(err.message);
              }
            });
      }
    });
  }

  setTableReference(table: GridComponent): void
  {
    this.table = table;
  }



}

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<form [formGroup]="form" style="width: 100%!important; height: 100%!important;">
  <mat-grid-list cols="12" rowHeight="fit" gutterSize="20px">

    <mat-grid-tile colspan="9">
      <div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch" class="tile-content">
        <div fxLayout fxLayoutWrap fxLayoutGap="25px">
          <mat-form-field appearance="standard" fxFlex="100" class="pr-4">
            <mat-label>Cautare</mat-label>
            <input matInput formControlName="searchTerm">
            <mat-icon matSuffix class="disabled-text">search</mat-icon>
            <mat-error *ngIf="form.get('searchTerm').errors?.serverError">{{ form.get('searchTerm').errors?.serverError }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-grid-tile>

   <mat-grid-tile colspan="1">
     <div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch" class="tile-content">
      <section class="checkbox-section" fxFlex="100">
        <mat-checkbox class="checkbox-margin" fxFlex="100" formControlName="isWhiteList">Whitelist</mat-checkbox>
      </section>
     </div>
   </mat-grid-tile>

    <mat-grid-tile colspan="1">
      <div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch" class="tile-content">
        <section class="checkbox-section" fxFlex="100">
          <mat-checkbox class="checkbox-margin" fxFlex="100" formControlName="isBlacklist">Blacklist</mat-checkbox>
        </section>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <button mat-raised-button color="primary" style="justify-content: flex-end;!important;margin-top: 25px;" (click)="onSubmitEvent()">
        <mat-icon>search</mat-icon>
      </button>
    </mat-grid-tile>

  </mat-grid-list>
</form>
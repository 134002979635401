<div id="nomenclatures.users.list" class="page-layout carded  fullwidth" fxLayout="column">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="header accent p-24 h-70" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Nomenclatoare</span>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Utilizatori</span>
            </div>
            <div class="h1 mt-16" style="width: 100%">
                Lista utilizatori
                <span style="float: right;">
                    <button mat-fab color="warn" aria-label="Adauga utilizator nou" (click)="addNewEntity()">
                        <mat-icon>add_user_altj</mat-icon>
                    </button>
                </span>

            </div>
        </div>
    </div>

    <mat-card fxFlex="100" class="mt-10">
        <div class="content p-24">
            <div class="mat-elevation-z8">
                <app-syncfusion-data-grid #grid [url]="this.tablePath"
                                          [actionRowComponentTemplate]="this.tableActionComponent"
                                          [expandRowComponentTemplate]="this.tableExpandComponent"
                                          [defaultSort]="{ field: 'id', direction: 'desc' }" [complexFilters]="false"
                                          [query]="query" tableId="user">
                </app-syncfusion-data-grid>
            </div>
        </div>
    </mat-card>




</div>

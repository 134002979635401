<div style="width: 100%!important;">
    <div class="row">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="col-md-12" *ngIf="columns.length > 0">
        <mat-accordion *ngIf="complexFilters">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filtre avansate
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="control-section">
                    <ejs-querybuilder #querybuilder width="70%" class="row" [enablePersistence]="true" [showButtons]="true">
                        <e-columns>
                            <ng-template #template ngFor let-column [ngForOf]="columns">
                                <e-column [field]="column.field" [label]="column.headerText" [type]="column.type"></e-column>
                            </ng-template>
                        </e-columns>
                    </ejs-querybuilder>
                    <div class="filters-buttons">
                        <button class="e-btn e-primary e-qbr-button m-4" (click)="checkForSavedFilters()" [disabled]="">Restore last filters</button> &nbsp;&nbsp;
                        <button class="e-btn e-success e-qbr-button m-4" (click)="getJson()" >Aplica Filtre</button>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="control-section grid">
            <ejs-grid #grid [id]="tableId" [allowPaging]='true' [showColumnChooser]='true' [allowReordering]='true' [selectionSettings]="selectionOptions"
                      [allowResizing]='true' [allowSorting]='true' [showColumnMenu]='true'
                      [allowFiltering]='true' [enablePersistence]='false' [dataSource]='data'
                      [pageSettings]='pageSettings' [filterSettings]='filterSettings' [toolbar]="toolbar"  [sortSettings]='sortOptions'
                      [resizeSettings]="resizeSettings" [query]="query"
                      (columnDataStateChange)="actionComplete($event)" (load)="onLoad()"
                      (dataBound)="dataBound($event)" (actionBegin)='actionBegin($event)' [allowMultiSorting]='true'
                      (actionComplete)="actionComplete($event)"  (actionFailure)="actionFail($event)" (rowSelected)="onSelectRow($event)">

                        <ng-template #detailTemplate let-data *ngIf="expandRowComponentTemplate">
                            <app-expand-row-template [data]="data" [template]="expandRowComponentTemplate" [table]="grid"></app-expand-row-template>
                        </ng-template>

                    <e-columns>
                    <!--selectable row-->
                    <span *ngIf="selectable">
                        <e-column type='checkbox' width='5' [allowSearching]="false" [allowFiltering]="false" [allowGrouping]="false" [allowSorting]="false"></e-column>
                    </span>
                    
                    <ng-template #template ngFor let-column [ngForOf]="columns">
                        <!--Standard-->
                        <span *ngIf="column.renderTemplate === null">
                            <e-column
                                      [field]="column.field" [headerText]="column.headerText" [visible]="column.visible"
                                      [type]="column.type" [showInColumnChooser]="column.showInColumnChooser"
                                      [showColumnMenu]="column.showColumnMenu" [allowEditing]="column.allowEditing"
                                      [disableHtmlEncode]="column.disableHtmlEncode"
                                      [isPrimaryKey]="column.isPrimaryKey"
                                      [allowSorting]="column.allowSorting" [allowFiltering]="column.allowFiltering"
                                      [allowGrouping]="column.allowGrouping" [allowSearching]="column.allowSearching"
                                      [width]="column.width" [format]="column.format">
                                
                              </e-column>
                        </span>
                        <!--Actions-->
                        <span *ngIf="column.renderTemplate === 'actions' && actionRowComponentTemplate !== null">
                           <e-column [field]="column.field" [headerText]="column.headerText" [visible]="column.visible"
                                     [type]="column.type" [showInColumnChooser]="column.showInColumnChooser"
                                     [showColumnMenu]="column.showColumnMenu" [allowEditing]="column.allowEditing"
                                     [disableHtmlEncode]="column.disableHtmlEncode"
                                     [isPrimaryKey]="column.isPrimaryKey"
                                     [width]="column.width" [allowSorting]="column.allowSorting"
                                     [allowFiltering]="column.allowFiltering" [allowGrouping]="column.allowGrouping"
                                     [allowSearching]="column.allowSearching">
                                       <ng-template #template let-data>
                                         <actions-template [template]="actionRowComponentTemplate" [params]="data" [tableReference]="grid" style="float: right">
                                         </actions-template>
                                      </ng-template>
                          </e-column>
                        </span>
                        <!--Image-->
                        <span *ngIf="column.renderTemplate === 'image'">
                           <e-column [field]="column.field" [headerText]="column.headerText" [visible]="column.visible"
                                     [type]="column.type" [showInColumnChooser]="column.showInColumnChooser"
                                     [showColumnMenu]="column.showColumnMenu" [allowEditing]="column.allowEditing"
                                     [disableHtmlEncode]="column.disableHtmlEncode"
                                     [isPrimaryKey]="column.isPrimaryKey"
                                     [width]="column.width" [allowSorting]="column.allowSorting"
                                     [allowFiltering]="column.allowFiltering"
                                     [allowGrouping]="column.allowGrouping" [allowSearching]="column.allowSearching">
                                   <ng-template #template let-data>
                                       <ngx-avatar size="60" [round]="false"
                                                   [src]="getElementValue(data, column.field)"></ngx-avatar>
                                       <!--<app-image [url]="getElementValue(data, column.field)"></app-image>-->
                                  </ng-template>
                          </e-column>
                        </span>
    
                        <span *ngIf="column.renderTemplate === 'checkbox'">
                           <e-column [field]="column.field" [headerText]="column.headerText" [visible]="column.visible"
                                     [type]="column.type" [showInColumnChooser]="column.showInColumnChooser"
                                     [showColumnMenu]="column.showColumnMenu" [allowEditing]="column.allowEditing"
                                     [disableHtmlEncode]="column.disableHtmlEncode"
                                     [isPrimaryKey]="column.isPrimaryKey"
                                     [width]="column.width" [allowSorting]="column.allowSorting"
                                     [allowFiltering]="column.allowFiltering"
                                     [allowGrouping]="column.allowGrouping" [allowSearching]="column.allowSearching">
                                   <ng-template #template let-data>
                                       <mat-checkbox class="example-margin" [checked]="getElementValue(data, column.field)" [disabled]="true"></mat-checkbox>
                                  </ng-template>
                          </e-column>
                        </span>

                        <span *ngIf="column.renderTemplate === 'notNullCheckbox'">
                           <e-column [field]="column.field" [headerText]="column.headerText" [visible]="column.visible"
                                     [type]="column.type" [showInColumnChooser]="column.showInColumnChooser"
                                     [showColumnMenu]="column.showColumnMenu" [allowEditing]="column.allowEditing"
                                     [disableHtmlEncode]="column.disableHtmlEncode"
                                     [isPrimaryKey]="column.isPrimaryKey"
                                     [width]="column.width" [allowSorting]="column.allowSorting"
                                     [allowFiltering]="column.allowFiltering"
                                     [allowGrouping]="column.allowGrouping" [allowSearching]="column.allowSearching">
                                   <ng-template #template let-data>
                                       <mat-checkbox class="example-margin" [checked]="(getElementValue(data, column.field) !== ' - ')" [disabled]="true"></mat-checkbox>
                                  </ng-template>
                          </e-column>
                        </span>
                        
                    </ng-template>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>
</div>

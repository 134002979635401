import {BaseModel} from '../../shared/entities/base.model';
import {CompanyModel} from './company.model';

export class UserModel extends BaseModel {
    uid: string;
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    isAdmin: boolean;
    company: CompanyModel = new CompanyModel();
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    isActive: boolean;
    company_id: number;
}

import { UserModel } from './user.model';

export class AuthRequestModel {
    email: string;
    password: string;
}

export class AuthResponseModel {
    access_token: string;
    token_type: string;
    expires_in: number;
    expire_at: Date;
    user: UserModel;
}

import {Component, Inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationMessageService} from '../../../shared/services/validation-message-handle';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../../../auth/models/user.model';
import {UserProfileModel} from '../../../auth/models/user-profile.model';
import {UserService} from '../../../auth/services/user.service';
import {confirmPasswordValidator} from '../../../auth/reset-password/reset-password.component';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from '../../../shared/entities/api-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {GenericApiCallService} from '../../../shared/services/generic-api-call.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-users-add-edit',
  templateUrl: './users-add-edit.component.html',
  styleUrls: ['./users-add-edit.component.css']
})
export class UsersAddEditComponent implements OnInit {

  public loading = false;

  public userModel: UserModel = new UserModel();
  public form: FormGroup;
  public userProfile: UserProfileModel;
  public modalService: MatDialog;


  @Input() runInModal: boolean = false;
  @Input() requestedUserUid: string = null;


  constructor(
      @Inject(MAT_DIALOG_DATA)
      private asModalData: any,
      private route: ActivatedRoute,
      private _formBuilder: FormBuilder,
      private userService: UserService,
      private validationMessageService: ValidationMessageService,
      private toastr: ToastrService,
      private router: Router,
      private genericApiCallService: GenericApiCallService,
      public dialogRef: MatDialogRef<UsersAddEditComponent>

  ) {

  }

  ngOnInit(): void {


    if (this.asModalData){
      this.runInModal = this.asModalData.runAsModal;
    }

    if (this.runInModal === true){
      this.requestedUserUid = this.asModalData.uid;
    }else{
      this.requestedUserUid = this.route.snapshot.queryParams['uid'];
    }

    this.createForm();

    if (this.requestedUserUid){
      this.getProfile();
    }else{

    }
  }


  private createForm(): void
  {

    // Reactive Form
    this.form = this._formBuilder.group({
      name : ['', Validators.required],
      email   : [(this.requestedUserUid)? { value: '', disabled: true} : { value: '', disabled: false}, Validators.required],
      password  : [''],
      password_confirmation   : ['', confirmPasswordValidator],
      isActive: ['true'],
    });

  }

  private getProfile(): void
  {
    this.loading = true;
    this.userService.getUserProfile(this.requestedUserUid)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: ApiResponseModel) => {
          const user: UserModel = new UserModel().map(response.payload);
          this.userProfile = response.payload;

          if (!this.userProfile){
            this.router.navigate(['/pages/404']);
            this.toastr.error('404', 'Pagina pe care o cautati nu exista sau s-a mutat. Va rugam sa contactati administratorul.');
          }

          this.form.patchValue({
            name: user.name,
            email: user.email,
            password: null,
            password_confirmation: null,
            isActive: user.isActive,
          });

        });
  }

  create(): void
  {
    this.loading = true;

    const userProfileModel =  new UserModel().map(this.form.getRawValue());

    this.userService.create(userProfileModel)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: ApiResponseModel) => {
          this.userProfile = response.payload;
          if (response.status === true) {
            this.toastr.success('Succes!', 'Utilizatorul a fost creeat cu succes!');

            this.dialogRef.close();
          }
        }, err => {
          if (err instanceof HttpErrorResponse) {
            this.validationMessageService.serverSideValidation(err, this.form, '' , false);
          }
        });
  }

  update(): void
  {
    this.loading = true;

    const userProfileModel =  new UserModel().map(this.form.getRawValue());
    userProfileModel.uid = this.requestedUserUid;

    this.userService.updateUserProfile(userProfileModel)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: ApiResponseModel) => {
          this.userProfile = response.payload;
          if (response.status === true){
            this.toastr.success('Succes!', 'Utilizatorul a fost modificat cu succes!');

            if (this.runInModal === false){
              this.router.navigate(['/nomenclatures/users']);
            }else{
              this.dialogRef.close();
            }

          }
        }, err => {
          if (err instanceof HttpErrorResponse) {
            this.validationMessageService.serverSideValidation(err, this.form, '' , false);
          }
        });
  }

  public compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.uid === o2.uid;
    } else {
      return false;
    }
  }

  onSave(): void
  {
    if (this.requestedUserUid){
      this.update();
    }else{
      this.create();
    }
  }
}

import {Component, Inject, Injectable, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../auth/services/user.service';
import {UsersTableActionsTemplateComponent} from './users-table-actions-template/users-table-actions-template.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {DeleteDialogComponent} from '../../../shared/components/delete-dialog/delete-dialog.component';
import {AppComponent} from '../../../app.component';
import {Router} from '@angular/router';
import {TableFilterModel} from '../../../shared/entities/Table.model';
import {Query} from '@syncfusion/ej2-data';
import {UsersAddEditComponent} from '../users-add-edit/users-add-edit.component';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})



@Injectable()

export class UsersListComponent implements OnInit {

  public loading: false;
  public tablePath: string;
  public tableActionComponent = null;
  public tableExpandComponent = null;
  public tabType: string = 'seller';
  public query: Query;

  @ViewChild('tableComponent', {read: true, static: true}) tableComponent;

  constructor(public dialog: MatDialog, public userService: UserService, private router: Router) {

  }

  ngOnInit(): void {
    this.refreshtable();
    this.tableActionComponent = UsersTableActionsTemplateComponent;
  }

  refreshtable(): void {
    this.tablePath = 'user/index';
    this.query = new Query();
  }

  addNewEntity(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'dialog-class';
    dialogConfig.width = '80vw';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialog = this.dialog.open(UsersAddEditComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      data: {
        requestedUid: null,
        runAsModal: true,
        uid: null
      }
    });


    dialog.afterClosed().subscribe(data => {
      this.refreshtable();
    });
  }

}

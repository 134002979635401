import {FuseNavigation} from '@fuse/types';


function getNavigation(): any {
    const user = JSON.parse(localStorage.getItem('user'));

    const menu = [];

    menu.push({
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: 'dashboard',
    });

    menu.push({
        id: 'nomenclatures',
        title: 'Nomenclatoare',
        icon: 'view_comfy',
        type: 'group',
        children: [
            {
                id: 'nomenclatures.users',
                title: 'Utilizatori',
                type: 'item',
                icon: 'assignment_ind',
                url: '/nomenclatures/users'
            },
            {
                id: 'nomenclatures.process',
                title: 'Procese',
                type: 'item',
                icon: 'assignment_ind',
                url: '/nomenclatures/process'
            },
        ]
    });

    return menu;
}

export const navigation: FuseNavigation[] = getNavigation();

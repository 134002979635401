import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiLogParametersModel} from '../entities/api-log-reader.model';
import {HttpApiService} from './http-api.service';
import {ApiResponseModel} from '../entities/api-response.model';

@Injectable({
    providedIn: 'root'
})

export class ApiLogReaderService {

    constructor(
        public httpApiService: HttpApiService,
        private router: Router,
    ) {

    }

    getItem(params?: ApiLogParametersModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('admin/log-reader/list', params);
    }


}



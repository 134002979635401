import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthRequestModel } from '../models/auth.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { locale as english } from '../i18n/en';
import { locale as romania } from '../i18n/ro';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ValidationMessageService} from '../../shared/services/validation-message-handle';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    returnUrl: string;
    loading = false;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private validationMessageService: ValidationMessageService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, romania);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        const checkAuthStatus = this.userService.checkAuth();

        if ( checkAuthStatus === true ) {
            this.router.navigateByUrl(this.returnUrl);
        }


        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.loginForm.patchValue({email: this.route.snapshot.queryParams['email'] || ''});

        // this.initCallCenter();
    }

    auth(): void{

        let authModel = new AuthRequestModel();
        authModel = this.loginForm.getRawValue();
        this.userService.setRedirectUrl(this.returnUrl);
        this.loading = true;
        this.userService.login(authModel)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
                if(response.status === true){
                    this.userService.setUserDetails(response.payload);
                    window.location.href = this.returnUrl;
                    //this.router.navigateByUrl(this.returnUrl);
                }else{
                    Swal.fire({
                        title: 'Autentificare',
                        html: 'Acest cont nu este inca activ! <br> Te rugam sa astepti sa il valideze un administrator. ',
                        icon: 'warning',
                        footer: 'Va multumim ca ati ales serviciile noastre!',
                    });
                }
            }, err => {
                if (err instanceof HttpErrorResponse) {

                    this.validationMessageService.serverSideValidation(err, this.loginForm, '' , true);
                }
            });



    }
}

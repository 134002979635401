import { BaseModel } from './base.model';

export class ApiLogReaderModel extends BaseModel {

    available_log_dates: Array<Date>;
    date: Date;
    filename: string;
    logs: Array<ApiLogModel>;
}


export class ApiLogModel extends BaseModel {
    timestamp: Date;
    env: string;
    type: string;
    message: string;
}



export class ApiLogParametersModel extends BaseModel {
    date: Date;
    type: string;
    env: string;
}


import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {GridComponent} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'actions-template',
  templateUrl: './actions-template.component.html',
  styleUrls: ['./actions-template.component.css']
})
export class ActionsTemplateComponent implements OnInit, AfterViewInit {

  @ViewChild('actionRowSlot', {static: false, read: ViewContainerRef }) actionRowSlot: ViewContainerRef;

  @Input() template: any;
  @Input() params: any;
  @Input() tableReference: GridComponent;

  constructor(
      private componentResolver: ComponentFactoryResolver
  ) {

  }

  ngOnInit(): void
  {

  }

  public ngAfterViewInit(): void
  {
    setTimeout(() => {
      const factory = this.componentResolver.resolveComponentFactory(this.template);
      const componentRef: any = this.actionRowSlot.createComponent(factory);
      componentRef.instance.setParams(this.params);
      componentRef.instance.setTableReference(this.tableReference)
    }, 10);
  }

}

import {Component, ElementRef, ViewChild, ViewContainerRef} from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss']
})
export class SampleComponent
{
    @ViewChild('test', {static: false}) container : any;

    public html = 'not set yet .. ';

    constructor() {

    }

    ngOnInit() {
        console.log('I-m init now!');
    }

}

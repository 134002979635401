<h1 mat-dialog-title>Confirmare</h1>

<div mat-dialog-content>
  <div fxFlex="100" class="p-24">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <p *ngIf="data.message">{{data.message}}</p>
    <form [formGroup]="form" style="max-width: 100%!important;">
      <div fxLayout fxLayoutWrap fxLayoutGap="25px">
        <mat-form-field appearance="standard" fxFlex="100" class="pr-4">
          <mat-label>Motiv blacklist</mat-label>
          <input matInput formControlName="reason">
          <mat-icon matSuffix class="disabled-text">message</mat-icon>
          <mat-error *ngIf="form.get('reason').errors?.serverError">{{ form.get('reason').errors?.serverError }}
          </mat-error>
        </mat-form-field>

      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions align="end" style="margin: 5px">
  <button mat-button (click)="onNoClick()">Anulare</button>
  <button mat-raised-button color="accent" [disabled]="form.invalid" (click)="onSave()" cdkFocusInitial>Salveaza</button>
</div>

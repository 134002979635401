import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {

    public confirmation: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}


    onClickYes(): void {
        this.confirmation = true;
        this.dialogRef.close(this.confirmation);
    }

    onNoClick(): void {
        this.confirmation = false;
        this.dialogRef.close(this.confirmation);
    }

}

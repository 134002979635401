<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <ngx-loading [show]="loading"></ngx-loading>
    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            ISF Agent - Process manager
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Pentru a avea acces la aceasta platforma, te rugam sa te autentifici folosind formularul din dreapta.
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div class="logo" [@animate]="{value:'*',params:{scale:'1'}}">
            <img src="assets/images/logos/logo.png">
        </div>

        <div id="login-form">

            <div class="title">Autentificare</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">Email-ul este obligatoriu</mat-error>
                    <mat-error *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email')">
                       Te rugam sa introduci o adresa de email valida
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Parola</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>Parola este obligatorie</mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me"> Tine-ma minte!</mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/auth/recover-password'">Ai uitat parola ?</a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" [disabled]="loginForm.invalid" (click)="auth()">Autentificare</button>

            </form>

            <!--
            <div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">

                <button mat-raised-button class="google">
                    Log in with Google
                </button>

                <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button>

            </div>
            -->
            <!--
            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Nu ai un cont ?</span>
                <a class="link" [routerLink]="'/auth/register'">Creaza-ti un cont </a>
            </div>
            -->
        </div>
    </div>
</div>

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { UserService } from '../services/user.service';
import {AuthRequestModel} from '../models/auth.model';
import {ResetPasswordRequestModel} from '../models/recover-password-request.model';

@Component({
    selector     : 'app-reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;
    passwordResetToken: any = null;
    passwordResetEmail: any = null;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.passwordResetToken  = this.route.snapshot.queryParams['token'];
        this.passwordResetEmail  = this.route.snapshot.queryParams['email'];

        this.resetPasswordForm = this._formBuilder.group({
            password       : ['', Validators.required],
            password_confirmation: ['', [Validators.required, confirmPasswordValidator]]
        });

        console.log('token is: -> ' + this.passwordResetToken);

        // Update the validity of the 'password_confirmation' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('password_confirmation').updateValueAndValidity();
            });
    }

    resetPassword(): void
    {
        let resetPasswordModel = new ResetPasswordRequestModel();

        resetPasswordModel = this.resetPasswordForm.getRawValue();
        resetPasswordModel.token = this.passwordResetToken;
        resetPasswordModel.email = this.passwordResetEmail;

        console.log(resetPasswordModel);

        this.userService.resetPassword(resetPasswordModel);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const password_confirmation = control.parent.get('password_confirmation');

    if ( !password || !password_confirmation )
    {
        return null;
    }

    if ( password_confirmation.value === '' )
    {
        return null;
    }

    if ( password.value === password_confirmation.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {BannedProcessModel} from '../types/process.model';
import {HttpApiService} from '../shared/services/http-api.service';
import {ApiResponseModel} from '../shared/entities/api-response.model';


@Injectable({
    providedIn: 'root'
})

export class ProcessService {

    constructor(
        public httpApiService: HttpApiService,
        private router: Router,
    ) {

    }

    banProcess(data: BannedProcessModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('admin/process/postBanned', data);
    }

    unbanProcess(name: string): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('admin/process/unbanProcess', {name: name});
    }


    putToWhiteList(data: BannedProcessModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('admin/process/postWhite', data);
    }

    removeFromWhitelist(name: string): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('admin/process/unbanWhiteProcess', {name: name});
    }

}



import { Component, OnInit } from '@angular/core';
import {ApiLogReaderService} from '../../services/ApiLogReaderService';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from '../../entities/api-response.model';
import {ApiLogReaderModel} from '../../entities/api-log-reader.model';

@Component({
  selector: 'app-api-log-reader',
  templateUrl: './api-log-reader.component.html',
  styleUrls: ['./api-log-reader.component.css']
})
export class ApiLogReaderComponent implements OnInit {

  public loading = false;
  public logs: ApiLogReaderModel = null;
  public step = 0;
  public list:  Array<any> = [{name: 'test', id: 1},{name: 'asdasdasdas', id: 2},{name: 'cccc', id: 3},{name: 'wwww', id: 4},{name: 'dddd', id: 6},{name: 'zxzxzxzxz', id: 11},{name: 'sdasdasdas', id: 12}]
  public selectedOption: any;
  public searchCtrl: any = '';
  public test: any = false;
  public showSearch: boolean = false;

  constructor(private apiLogReaderService: ApiLogReaderService,
              private route: ActivatedRoute,
              private _formBuilder: FormBuilder,
              private toastr: ToastrService,
              private router: Router) {

  }


  ngOnInit(): void
  {
    this.getLogs();
  }

  setStep(index: string) {
    this.step = parseInt(index);
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getLogs(): void
  {
    this.loading = true;
    this.apiLogReaderService.getItem()
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: ApiResponseModel) => {
            this.logs = new ApiLogReaderModel().map(response.payload);
            console.log(this.logs);
        });
  }

  getIcon(type: string): string
  {
    switch(type) {
      case 'INFO':
        return 'info';
        break;

      case 'ERROR':
        return 'error_outline';
        break;

      case 'WARNING':
        return 'warning';
        break;

      case 'NOTICE':
        return 'notification_important';
        break;

      case 'CRITICAL':
        return 'warning';
        break;


      case 'ALERT':
        return 'report';
        break;

      case 'DEBUG':
        return 'gesture';
        break;


      case 'EMERGENCY':
        return 'warning';
        break;

        default:
        // code block
    }
  }
}

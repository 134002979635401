import {Component, OnInit, ViewEncapsulation, Input, Optional, Self, Output, EventEmitter, ViewChild} from '@angular/core';
import { Query, DataManager, WebApiAdaptor, UrlAdaptor } from '@syncfusion/ej2-data';
import {HttpApiService} from '../../../services/http-api.service';
import {ApiDataAdaptor} from '../../../services/syncfusion/api-data-adaptor';
import {ControlContainer, FormControl, FormGroupDirective} from '@angular/forms';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-syncfusion-drop-down-list',
  templateUrl: './syncfusion-drop-down-list.component.html',
  styleUrls: ['./syncfusion-drop-down-list.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})

export class SyncfusionDropDownListComponent implements OnInit {

  @Input() label: string = '';
  @Input() field: string = '';


  @Input() endPoint;
  @Input() dataArray: Array<any> = [];
  @Input() params: Array<{field, value}> = [];


  @Input() set controlName(value) {
    this.formControl = this.parentF.form.get(value) as FormControl
  }
  @Input() readonly = false;

  @Output() onChangeEvent = new EventEmitter<any>();
  @Output() onLoadedData = new EventEmitter<any>();

  @ViewChild('selector', {static: false})


  public dropDownListObject = DropDownListComponent;
  public formControl: FormControl;

  // bind the Query instance to query property
  public query: Query;
  // maps the remote data column to fields property
  // set the height of the popup element
  public height: string = '200px';
  // set the placeholder to DropDownList input element
  public data: any;
  @Input() fields = { text: 'name', value: 'id' };

  constructor(public httpApiService: HttpApiService, private parentF: FormGroupDirective) {

  }

  ngOnInit(): void
  {

    if(this.field){
      this.formControl = this.parentF.form.get(this.field) as FormControl
    }

    if(this.endPoint){
      this.setFilters();
      this.initDataManager();
    }else{
      this.initArrayDataManager();
    }

  }

  public onFiltering(e: FilteringEventArgs): any  {

    if(e.text !== '' && e.text.length >= 3){
      this.setFilters();
      this.query.addParams('searchText', e.text);
      this.query.addParams('searchTextField', this.fields.text);
    }

    e.updateData(this.data, this.query);
  };

  initArrayDataManager(): void
  {
    this.data = this.dataArray;
  }

  getUrl(): string
  {

    const isFullUrl = this.endPoint.includes('/');

    if(isFullUrl){
      return this.httpApiService.getUrl(this.endPoint);
    }else{
      return this.httpApiService.getUrl('generic-call/abstractExpose?entity='+this.endPoint);
    }
  }

  initDataManager(): void
  {

    this.data = new DataManager({
      url: this.getUrl(),
      crossDomain: true,
      headers: [{'Authorization': this.httpApiService.getAuthToken()},
        {'Access-Control-Allow-Origin': '*'},
        {'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS'},
        {'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type'}],
      adaptor: new ApiDataAdaptor(),
      accept: true,
      dataType: 'application/json',
    }, this.query);
  }

  public setFilters(): void
  {

    this.query = new Query();
    this.query.select([this.fields.value, this.fields.text]);

    this.params.forEach((p) => {
      this.query.addParams(p.field, p.value)
    });

    this.query.take(100).requiresCount();
  }

  public onChange($event: any): void
  {
    this.onChangeEvent.emit($event);
  }

  public onLoaded($event: Event) {
    this.onLoadedData.emit($event);
  }

  public refresh(): void
  {
    this.setFilters();
    this.query.take(100).requiresCount();
  }

}


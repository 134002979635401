import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()

export class TableCommunicationService {

    public requestComplete = new Subject<any>();
    requestComplete$ = this.requestComplete.asObservable();

    private tableReloadEvent = new Subject<any>();
    tableReloadEvent$ = this.tableReloadEvent.asObservable();

    private tableSetParameters = new Subject<any>();
    tableSetParameters$ = this.tableSetParameters.asObservable();

    reload(data?: any): void
    {
        this.tableReloadEvent.next(data);
    }

    setParameters(data: any): void
    {
        this.tableSetParameters.next(data);
    }


}

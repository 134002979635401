import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersAddEditComponent } from './users/users-add-edit/users-add-edit.component';
import {AuthGuardService} from '../guards/auth-guard.service';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {ToastrModule} from 'ngx-toastr';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from '../shared/shared.module';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {
    MAT_DIALOG_DATA,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule, MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule, MatChipsModule,
    MatDatepickerModule, MatDialog,
    MatDialogModule,
    MatDialogRef, MatExpansionModule,
    MatFormFieldModule, MatGridListModule,
    MatIconModule,
    MatInputModule, MatListModule, MatMenuModule,
    MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { UsersTableActionsTemplateComponent } from './users/users-list/users-table-actions-template/users-table-actions-template.component';
import {DeleteDialogComponent} from '../shared/components/delete-dialog/delete-dialog.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarAllModule} from '@syncfusion/ej2-angular-calendars';
import {AgmCoreModule} from '@agm/core';
import {MessageDialogComponent} from '../shared/components/message-dialog/message-dialog.component';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {FuseMaterialColorPickerModule} from '../../@fuse/components';
import {AuthIsAdminService} from '../guards/auth-is-admin.service';
import {PusherService} from '../services/socket/pusher.service';
import {
    SyncfusionDataGridComponent
} from '../shared/components/syncfusion/syncfusion-data-grid/syncfusion-data-grid.component';
import {TableCommunicationService} from '../shared/services/table-communication.service';
import { ProcessListComponent } from './process/process-list/process-list.component';
import { ProcessListActionTemplateComponent } from './process/process-list/process-list-action-template/process-list-action-template.component';
import { ProcessListFiltersComponent } from './process/process-list/process-list-filters/process-list-filters.component';
import { AddProcessInBlacklistComponent } from './process/process-list/add-process-in-blacklist/add-process-in-blacklist.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


const routes = [
  {
    path     : 'nomenclatures/process',
    component: ProcessListComponent,
    canActivate: [AuthGuardService],
  },

  {
    path     : 'nomenclatures/users',
    component: UsersListComponent,
    canActivate: [AuthGuardService],
  },

  {
    path     : 'nomenclatures/users/add-edit',
    component: UsersAddEditComponent,
    canActivate: [AuthGuardService],
  },


];

@NgModule({
    declarations: [UsersListComponent, UsersAddEditComponent, UsersTableActionsTemplateComponent, ProcessListComponent, ProcessListActionTemplateComponent,
        ProcessListFiltersComponent,
        AddProcessInBlacklistComponent],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDWnbY0EpygnBwGfbeEyxiFEvAumc8BK5A', libraries: ['places']
        }),
        PerfectScrollbarModule,
        NgxMaterialTimepickerModule,
        MatDialogModule,
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        ToastrModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '8px',
            primaryColour: '#038bce',
            secondaryColour: '#3e67ce',
            tertiaryColour: '#a25bce'
        }),

        // Material
        MatButtonModule,
        MatIconModule,
        MatMomentDateModule,
        MatCheckboxModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        CalendarAllModule,
        MatFormFieldModule,
        SharedModule,
        AgmCoreModule,
        NgxMaskModule,
        FuseMaterialColorPickerModule,
    ],
    providers: [
        AuthGuardService, PusherService, TableCommunicationService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}

    ],
    entryComponents: [UsersTableActionsTemplateComponent, DeleteDialogComponent, MessageDialogComponent, UsersAddEditComponent,
        SyncfusionDataGridComponent, ProcessListActionTemplateComponent, AddProcessInBlacklistComponent],
    exports: [UsersAddEditComponent, AddProcessInBlacklistComponent]
})

export class NomenclaturesModule { }




import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {TableComponent} from './components/table/table.component';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatDividerModule
} from '@angular/material';

import {DeleteDialogComponent} from './components/delete-dialog/delete-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BaseComponentComponent} from './components/base-component/base-component.component';
import {ActionColumnComponent} from './components/table/action-column/action-column.component';
import {FlexModule} from '@angular/flex-layout';
import {ApiLogReaderComponent} from './components/api-log-reader/api-log-reader.component';
import {AuthGuardService} from '../guards/auth-guard.service';
import {RouterModule} from '@angular/router';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {StringFilterByPipe} from './pipes/StringFilterByPipe';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';
import {GridAllModule} from '@syncfusion/ej2-angular-grids';
import {SyncfusionDropDownListComponent} from './components/syncfusion/syncfusion-drop-down-list/syncfusion-drop-down-list.component';
import {DropDownListAllModule, DropDownListModule, MultiSelectModule} from '@syncfusion/ej2-angular-dropdowns';
import {SliderModule} from '@syncfusion/ej2-angular-inputs';
import {CheckBoxModule, RadioButtonModule} from '@syncfusion/ej2-angular-buttons';
import {QueryBuilderModule} from '@syncfusion/ej2-angular-querybuilder';
import {SampleModule} from '../main/sample/sample.module';
import {SyncfusionDataGridComponent} from './components/syncfusion/syncfusion-data-grid/syncfusion-data-grid.component';
import {ImageComponent} from './components/syncfusion/syncfusion-data-grid/columns/image/image.component';
import {ActionsTemplateComponent} from './components/syncfusion/syncfusion-data-grid/columns/actions-template/actions-template.component';
import {AvatarModule} from 'ngx-avatar';
import {ExpandRowTemplateComponent} from './components/syncfusion/syncfusion-data-grid/expand-row-template/expand-row-template.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatIconModule} from '@angular/material/typings/icon';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };
const routes = [
    {
        path: 'logs/api-log-reader',
        component: ApiLogReaderComponent,
        canActivate: [AuthGuardService],
    }
];

@NgModule({

    declarations: [TableComponent, DeleteDialogComponent, BaseComponentComponent, ActionColumnComponent, ApiLogReaderComponent, StringFilterByPipe, MessageDialogComponent, SyncfusionDataGridComponent, SyncfusionDropDownListComponent, ImageComponent, ActionsTemplateComponent, ExpandRowTemplateComponent, UploadFileComponent],
    imports: [
        PerfectScrollbarModule,
        CommonModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#038bce',
            secondaryColour: '#3e67ce',
            tertiaryColour: '#a25bce'
        }),
        RouterModule.forChild(routes),
        MatTableModule,
        MatDividerModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatSortModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        FlexModule,
        MatExpansionModule,
        MatRippleModule,
        MatSidenavModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        FormsModule,
        GridAllModule,
        DropDownListAllModule,
        QueryBuilderModule,
        SliderModule,
        MultiSelectModule,
        DropDownListModule,
        RadioButtonModule,
        CheckBoxModule,
        MatBadgeModule,
        SampleModule,
        AvatarModule,
        NgxMaterialTimepickerModule,
        NgxGalleryModule,
    ],
    providers: [DatePipe, DecimalPipe, {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}],
    exports: [TableComponent, DeleteDialogComponent, MessageDialogComponent, SyncfusionDropDownListComponent, SyncfusionDataGridComponent, NgxGalleryModule, UploadFileComponent],
    entryComponents: [DeleteDialogComponent, MessageDialogComponent],

})

export class SharedModule {
}

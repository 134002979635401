import {Injectable} from '@angular/core';
import {UrlAdaptor} from '@syncfusion/ej2-data';
import {ApiResponseModel} from '../../entities/api-response.model';
import {TableCommunicationService} from '../table-communication.service';

export class ApiDataAdaptor extends UrlAdaptor {
    public processResponse(): object {

        let request: ApiResponseModel = new ApiResponseModel().map(super.processResponse.apply(this, arguments));

        let result = [];
        let count = 0;

        if(request.status === true){
            count = request.payload.length;
            result = request.payload;
        }

        return {result: result, count: count};
    }
}


export class ApiTableDataAdaptor extends UrlAdaptor {

    constructor() {
        super();
    }

    public processResponse(): object {

        let request: ApiResponseModel = new ApiResponseModel().map(super.processResponse.apply(this, arguments));

        let result = [];
        let count = 0;
        let fields = [];

        if( request.status === true ){
            count = request.payload.total;
            result = request.payload.items;
            fields = request.payload.fields;
        }else {
            // TODO
            // handle error here!
            // if not auth, redirect to login.
        }

        return {result: result, count: count, fields: fields};
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthGuardService} from '../guards/auth-guard.service';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AgmCoreModule} from '@agm/core';
import {
  MAT_DIALOG_DATA,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule, MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule, MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {ToastrModule} from 'ngx-toastr';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {CalendarAllModule} from '@syncfusion/ej2-angular-calendars';
import {DropDownListAllModule} from '@syncfusion/ej2-angular-dropdowns';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {ToastUiImageEditorModule} from 'ngx-tui-image-editor';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {AvatarModule} from 'ngx-avatar';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {FuseWidgetModule} from '../../@fuse/components';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const routes = [

    {
        path     : 'settings/general-settings',
        component: GeneralSettingsComponent,
        canActivate: [AuthGuardService],
    },
];

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
  declarations: [GeneralSettingsComponent],
    imports: [
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDWnbY0EpygnBwGfbeEyxiFEvAumc8BK5A', libraries: ['places']
        }),
        MatDialogModule,
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        ToastrModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        PerfectScrollbarModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '8px',
            primaryColour: '#038bce',
            secondaryColour: '#3e67ce',
            tertiaryColour: '#a25bce'
        }),

        // Material
        MatButtonModule,
        MatIconModule,
        MatMomentDateModule,
        MatCheckboxModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        CalendarAllModule,
        MatFormFieldModule,
        SharedModule,
        AgmCoreModule,
        DropDownListAllModule,
        NgxMatSelectSearchModule,
        NgxSmartModalModule.forRoot(),
        ToastUiImageEditorModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatMomentModule,
        AvatarModule,
        NgxMaskModule.forRoot(maskConfig),
        FuseWidgetModule,
    ],
  providers: [
    AuthGuardService,
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
  ],
  entryComponents: [

  ]
})

export class SettingsModule { }

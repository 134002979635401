<ngx-loading [show]="loading"></ngx-loading>

<button mat-icon-button color="warn" aria-label="Edit" (click)="addToBan()"  matTooltip="Adauga procesul in blacklist" *ngIf="!processModel.bannedProcess && !processModel.whiteProcess">
    <mat-icon>block</mat-icon>
</button>

<button mat-icon-button color="primary" aria-label="Unban process" (click)="unban()"  matTooltip="Scoate procesul din blacklist" *ngIf="processModel.bannedProcess">
    <mat-icon>update</mat-icon>
</button>

<button mat-icon-button color="primary" aria-label="Unban process" (click)="removeFromWhiteList()"  matTooltip="Scoate procesul din whitelist" *ngIf="processModel.whiteProcess">
    <mat-icon>update</mat-icon>
</button>
<button mat-icon-button color="accent" aria-label="White list" (click)="addToWhite()" matTooltip="Adauga procesul in whitelist" *ngIf="!processModel.bannedProcess && !processModel.whiteProcess">
    <mat-icon>verified_user</mat-icon>
</button>
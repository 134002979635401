import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { AuthGuardService } from '../guards/auth-guard.service';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule, MatInputModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatSelectModule
} from '@angular/material';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexModule} from '@angular/flex-layout';
import {BarChartModule, PieChartModule} from '@swimlane/ngx-charts';
import {SharedModule} from '../shared/shared.module';
import {FormGroupDirective, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {SyncfusionDropDownListComponent} from '../shared/components/syncfusion/syncfusion-drop-down-list/syncfusion-drop-down-list.component';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import {ChartsModule} from 'ng2-charts';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

const routes = [
    {
        path     : 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
    },

];

@NgModule({
  declarations: [DashboardComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatCardModule,
        NgxLoadingModule,
        MatIconModule,
        FlexModule,
        PieChartModule,
        BarChartModule,
        TranslateModule,
        SharedModule,
        MatGridListModule,
        ReactiveFormsModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        MatCheckboxModule,
        ChartsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        NgxMatSelectSearchModule,
    ],
    providers: [AuthGuardService, SyncfusionDropDownListComponent, FormGroupDirective],
    entryComponents: [],
    exports: [SyncfusionDropDownListComponent]
})
export class HomeModule {

}


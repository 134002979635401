import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ComponentFactoryResolver,
  Inject, Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {GridComponent} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-expand-row-template',
  templateUrl: './expand-row-template.component.html',
  styleUrls: ['./expand-row-template.component.css']
})
export class ExpandRowTemplateComponent implements OnInit, AfterViewInit {

  @Input() template: any;
  @Input() data: any;
  @Input() table: GridComponent;
  @ViewChild('containerSlot', {static: false, read: ViewContainerRef }) containerSlot: ViewContainerRef;

  constructor(
      @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
      private componentResolver: ComponentFactoryResolver,
      private injector: Injector,
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const factory = this.componentResolver.resolveComponentFactory(this.template);
      const componentRef: any = this.containerSlot.createComponent(factory);
      componentRef.instance.setParams(this.data, this.table);
    }, 50);
  }



}

import {BaseModel} from '../../shared/entities/base.model';


export class CompanyModel extends BaseModel {

    id: number;
    name: string;
    uid: string;
    uniqueCode: string;
    nrRegCom: string;
    email: string;
    phone: string;
    website: string;
    isActive: boolean;

}

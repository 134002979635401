import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpApiService} from './http-api.service';
import {ApiResponseModel} from '../entities/api-response.model';

@Injectable({
    providedIn: 'root'
})

export class GenericApiCallService {

    constructor(public httpApiService: HttpApiService) {

    }

    getOptions(entity: string, searchText?: string, params?: any): Observable<ApiResponseModel> {

        const data = {
            'entity': entity,
            'params': JSON.stringify(params),
            'searchText': searchText,
        };

        return this.httpApiService.httpGet('generic-call/abstractExpose', data);
    }

    getLocalizationByLocality(localityUid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('generic-call/getLocalizationByLocality', {uid: localityUid});
    }

}



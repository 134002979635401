import {Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import {Observable, Subject} from 'rxjs';
import {HttpApiService} from '../../shared/services/http-api.service';


@Injectable()

export class PusherService {

  public pusher: any;
  public channel: any;

  private endPointUrl = 'http://api-freya-analyzer.stdev.ro/websockets/event';
  private authEndpoint = 'http://api-freya-analyzer.stdev.ro/websockets/auth';
  private appKey = 'autosel_key';
  private secret = 'autosel_secret';
  private appId = '1477';
  private cluster = 'mt1';
  private httpHost = 'http://api-freya-analyzer.stdev.ro/';
  private httpPort = '80';
  private httpPath = 'websockets';

  private wsHost = 'api-freya-analyzer.stdev.ro';
  private wsPort = 9000;
  private wsPath = '';

  constructor(private http: HttpApiService) {
    this.connect();
  }

  public connect(): void
  {
    console.log('PUSHER CONNECT FUNCTION!');
    Pusher.logToConsole = false;

    const options = {
      authEndpoint: this.authEndpoint,
      auth: {
        headers: {
          'Accept': '*/*',
          'Authorization': this.http.getAuthToken(),
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'x-app-id': this.appId,
        }
      },
      cluster: this.cluster,
      httpHost: this.httpHost,
      httpPort: this.httpPort,
      httpPath: this.httpPath,
      wsHost: this.wsHost,
      wsPort: this.wsPort,
      wsPath: this.wsPath,
      enabledTransports: ['ws'],
      disabledTransports: ['wss', 'xhr_streaming', 'xhr_polling', 'sockjs'],
      forceTLS: false,
    };

    // @ts-ignore
    this.pusher = new Pusher(this.appKey, options);
  }

  public sendData(channel: string, event: string, object: any): Observable<any> {

    const formData = new FormData();
    formData.append('key', this.appKey);
    formData.append('appId', this.appId);
    formData.append('secret', this.secret);
    formData.append('channel', channel);
    formData.append('event', event);
    formData.append('data', JSON.stringify(object));

    return this.http.httpSocketPost(this.endPointUrl, formData);

  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserModel} from '../../../../auth/models/user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProcessModel} from '../../../../types/process.model';
import {confirmPasswordValidator} from '../../../../auth/reset-password/reset-password.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../auth/services/user.service';
import {ValidationMessageService} from '../../../../shared/services/validation-message-handle';
import {ToastrService} from 'ngx-toastr';
import {GenericApiCallService} from '../../../../shared/services/generic-api-call.service';

@Component({
  selector: 'app-add-process-in-blacklist',
  templateUrl: './add-process-in-blacklist.component.html',
  styleUrls: ['./add-process-in-blacklist.component.css']
})
export class AddProcessInBlacklistComponent implements OnInit {


  public loading = false;

  public processModel: ProcessModel = new ProcessModel();
  public form: FormGroup;


  constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: any,
      private route: ActivatedRoute,
      private _formBuilder: FormBuilder,
      private validationMessageService: ValidationMessageService,
      private toastr: ToastrService,
      private router: Router,
      private genericApiCallService: GenericApiCallService,
      public dialogRef: MatDialogRef<AddProcessInBlacklistComponent>

  ) {
    this.processModel.map(data.process);
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void
  {

    // Reactive Form
    this.form = this._formBuilder.group({
      reason : ['', Validators.required],
    });

  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onSave(): void {

    this.dialogRef.close({
      name: this.processModel.name,
      reason: this.form.get('reason').value
    });
  }
}

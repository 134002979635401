import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef, EventEmitter,
  Inject, Injector,
  Input,
  OnInit, Output, QueryList,
  ViewChild, ViewChildren, ViewContainerRef
} from '@angular/core';
import {
  ColumnChooserService, ColumnMenuService,
  GroupService, PageService,
  ResizeService,
  SortService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import {GridComponent, ColumnModel, Column} from '@syncfusion/ej2-angular-grids';
import {QueryBuilderComponent} from '@syncfusion/ej2-angular-querybuilder';
import {HttpApiService} from '../../../services/http-api.service';
import {TableCommunicationService} from '../../../services/table-communication.service';
import { Query, DataManager, WebApiAdaptor, UrlAdaptor } from '@syncfusion/ej2-data';
import {ApiTableDataAdaptor} from '../../../services/syncfusion/api-data-adaptor';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from '../../../entities/api-response.model';
import {DatePipe, DecimalPipe} from '@angular/common';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {DomSanitizer} from '@angular/platform-browser';
import { setSpinner } from '@syncfusion/ej2-angular-popups';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';

// setSpinner({ template: '<p>Va rugam sa asteptati. Se incarca in cateva momente...</p>'});

@Component({
  selector: 'app-syncfusion-data-grid',
  templateUrl: './syncfusion-data-grid.component.html',
  styleUrls: ['./syncfusion-data-grid.component.scss'],
  providers: [ToolbarService, ColumnChooserService, SortService, ResizeService, GroupService, ColumnMenuService, PageService]
})

export class SyncfusionDataGridComponent implements OnInit, AfterViewInit {

  @Input() selectableKey: string = null;
  @Input() url: string = null;
  @Input() query: Query;
  @Input() complexFilters = false;
  @Input() tableId = null;
  @Input() perPage = 10;
  @Input() actionRowComponentTemplate: any;
  @Input() expandRowComponentTemplate: any;
  @Input() defaultSort: { field: string, direction: string };

  @Input() selectable: boolean;
  @Output() onLoadData = new EventEmitter<string>();
  @Output() onSelectRowEvent = new EventEmitter<Array<any>>();

  @ViewChild('grid', {static: false}) grid: GridComponent;
  @ViewChild('querybuilder', {static: false}) querybuilder: QueryBuilderComponent;
  @ViewChild('detailTemplate', {static: false}) detailTemplate: any;

  public showTable = false;
  public loading = false;
  public data: DataManager;
  public pageSettings;
  public sortOptions: object;
  public filterSettings;
  public resizeSettings = { mode: 'Auto' };
  public toolbar: string[];
  public columns: Array<any> = [];
  public showFilters: boolean = false;
  public selectionOptions: SelectionSettingsModel;


  constructor(
      @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
      private componentResolver: ComponentFactoryResolver,
      private injector: Injector,
      public httpApiService: HttpApiService,
      private tableCommunicationService: TableCommunicationService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private sanitizer: DomSanitizer,
      private elRef: ElementRef,
      private datePipe: DatePipe,
      private decimalPipe: DecimalPipe,
  ) {

  }

  ngOnInit(): void {
    this.toolbar = ['ColumnChooser'];
    this.pageSettings = { pageSizes: true, pageSize: this.perPage };
    this.sortOptions = { columns: [this.defaultSort] };
    this.filterSettings = { type: 'Menu' };
    this.selectionOptions = { mode: 'Row', type: 'Multiple', checkboxOnly: true, persistSelection: true};
    (!this.query) ? this.query = new Query() : this.query;
    this.getColumns();
    // console.log('Selectable key -> ', this.selectableKey);
  }

  ngAfterViewInit(): void {

  }

  private getColumns(): void {
    this.loading = true;
    const parameters = { onlyColumns: true };

    this.query.params.forEach(e => {
      parameters[e.key] = e.value;
    });

    this.httpApiService.httpPost(this.url, parameters)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: ApiResponseModel) => {
          this.columns = response.payload;
          this.initData();
        });
  }

  private initData(): void {
    this.data = new DataManager({
      url: this.httpApiService.getUrl(this.url),
      crossDomain: true,
      headers: [
        {Authorization: this.httpApiService.getAuthToken()},
        {'Access-Control-Allow-Origin': '*'},
        {'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS'},
        {'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type'}
      ],
      adaptor: new ApiTableDataAdaptor(),
      accept: true,
      dataType: 'application/json',
    }, this.query);

    this.data.setDefaultQuery(this.query);

  }

  public dataBound(args: any): void {
    // (this.grid.columns[0] as any).isPrimaryKey = 'true';
    // this.grid.autoFitColumns([]);
    // this.applyCols();

      this.setCheckboxStatus();

    Object.assign((this.grid.filterModule as any).filterOperators, { startsWith: 'contains' });
  }

  private getElementValue(obj: any, is: any, value?: string): any {

    try{

      if (typeof is === 'string'){
        return this.getElementValue(obj, is.split('.'), value);
      } else if (is.length === 1 && value !== undefined){
        return obj[is[0]] = value;
      } else if (is.length === 0) {
        return obj;
      } else {
        return this.getElementValue(obj[is[0]], is.slice(1), value);
      }

    }catch (e) {
      return ' - ';
    }
  }

  public formatValue(value: any, format: any): any {
    switch ( format ) {
      case 'translate':
        // TODO Translate implementation
        return value + ' - trans';
      case 'datetime':
        return this.datePipe.transform(value, 'dd-MM-yyyy HH:mm');
      case 'date':
        return this.datePipe.transform(value, 'dd-MM-yyyy');
      case 'float':
        return this.decimalPipe.transform(value, '1.2-2');
      case 'boolean':
        return (value === true || value === 1) ? 'Da' : 'Nu';
      case 'boolean_null':
        return (value) ? 'Nu' : 'Da';
      default:
        return value;
    }

  }

  public getSelectedRows(): Object[] {
    return this.grid.getSelectedRecords();
  }

  public onSelectRow($event): void {
    this.onSelectRowEvent.emit(this.getSelectedRows());
  }

  public actionBegin($event: any): void {
    // this.loading = true;
  }

  public actionComplete(e: any): void {
    // this.setCheckboxStatus();
  }

  public setCheckboxStatus(): void{
      // const selectableKey = this.selectableKey;
      const selectableKey = 'locationCompany';
      // TODO Change this selectable key as input parameter in this component. (not working now, return null)
      if (this.selectable){
          // this.grid.selectRow(1, true);
          const rowsData = this.grid.getRows();

          setTimeout(e => {
              if (rowsData.length > 0){
                  rowsData.forEach(field => {
                      const object = this.grid.getRowInfo(field);
                      if (object.rowData[selectableKey]){
                          this.grid.selectRow(object.rowIndex, true);
                      }
                  });
              }
          }, 100);
      }
  }

  public actionFail(e: any): void {
    // this.loading = false;
  }

  public onLoad(): void {
    // event capturing used
        this.grid.element.addEventListener('click', this.collapseAll.bind(this), true);
        this.onLoadData.emit('dataLoaded!');
        this.setCheckboxStatus();
  }

  public collapseAll(args): void {
    const tgt = args.target;

    if (tgt.closest('.e-grid').getAttribute('id') !== this.grid.element.getAttribute('id')) {
      // for child grid
      if(tgt.classList.contains('e-dtdiagonalright') || tgt.classList.contains('e-detailrowcollapse')) {
        var inst = tgt.closest('.e-grid').ej2_instances[0];
        inst.detailRowModule.collapseAll();
      } else if (tgt.classList.contains('e-dtdiagonaldown') || tgt.classList.contains('e-detailrowexpand')) {
        var inst = tgt.closest('.e-grid').ej2_instances[0];
      }
    } else if(tgt.classList.contains('e-dtdiagonalright') || tgt.classList.contains('e-detailrowcollapse')) {
      // for Parent Grid Alone
      this.grid.detailRowModule.collapseAll();
    } else if (tgt.classList.contains('e-dtdiagonaldown') || tgt.classList.contains('e-detailrowexpand')) {
    }
  }

  public checkForSavedFilters(): void {
    const rules = localStorage.getItem('auctions_table_filters');
    if (rules){
      this.querybuilder.setRules(JSON.parse(rules));
      this.getJson();
    }
    this.showTable = true;
  }

  public getSql(): void {
    const sql = this.querybuilder.getSqlFromRules(this.querybuilder.getRules());
    this.query.addParams('sqlConditions', sql).take(this.pageSettings.pageSize);
    this.data.executeQuery(this.query).then(e => {

    });
  }

  public getJson(): void {
    /*// Save filters to local storage.this.data.setDefaultQuery(this.query);
    const rules = JSON.stringify({ condition: this.querybuilder.rule.condition, rules: this.querybuilder.rule.rules }, null, 4) ;
    localStorage.setItem('auctions_table_filters', rules);*/

    this.query = this.grid.getQuery();
    this.query.where(this.querybuilder.getPredicate(this.querybuilder.rule));
    this.grid.query = this.query;
    this.grid.refresh();
  }

  public refresh(): void {
    this.grid.refresh();
  }

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ValidationMessageService} from '../../../../shared/services/validation-message-handle';

@Component({
  selector: 'app-process-list-filters',
  templateUrl: './process-list-filters.component.html',
  styleUrls: ['./process-list-filters.component.css']
})
export class ProcessListFiltersComponent implements OnInit {

  public loading = false;
  public form: FormGroup;

  @Output() onFiltersSubmit = new EventEmitter<Array<any>>();

  constructor(
      private route: ActivatedRoute,
      private _formBuilder: FormBuilder,
      private validationMessageService: ValidationMessageService,
      private toastr: ToastrService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void
  {

    // Reactive Form
    this.form = this._formBuilder.group({
      searchTerm : [''],
      isBlacklist: [false],
      isWhiteList: [false],
    });

  }

  public onSubmitEvent(): void {
    const data = this.form.getRawValue();
    this.onFiltersSubmit.emit(data);
  }

}

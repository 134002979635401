<div id="nomenclatures.process.list" class="page-layout carded  fullwidth" fxLayout="column">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="header accent p-24 h-70" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">home</mat-icon>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">Nomenclatoare</span>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">Procese</span>
      </div>
      <div class="h1 mt-16" style="width: 100%">
        Lista procese
      </div>
    </div>
  </div>

  <mat-card fxFlex="100" class="mt-10">
    <div class="content p-24">
      <div class="mat-elevation-z8">
        <div class="content p-24">
          <app-process-list-filters (onFiltersSubmit)="applyFilter($event)"></app-process-list-filters>
        </div>
        <app-syncfusion-data-grid #grid [url]="this.tablePath" [perPage]="50"
          [actionRowComponentTemplate]="this.tableActionComponent"
          [expandRowComponentTemplate]="this.tableExpandComponent"
          [defaultSort]="{ field: 'name', direction: 'asc' }" [complexFilters]="false" tableId="process.list">
        </app-syncfusion-data-grid>
      </div>
    </div>
  </mat-card>




</div>

import {BaseEntityModel, BaseModel} from '../shared/entities/base.model';

export class ProcessModel extends BaseEntityModel {

    HandleCount: number;
    MachineName: string;
    MainWindowTitle: string;
    arhitecture: string;
    bannedProcess: BannedProcessModel;
    whiteProcess: WhiteProcessModel;
    computerName: string;
    email: string;
    fileName: string;
    name: string;
    pid: string;

}


export class BannedProcessModel extends BaseEntityModel {

    _id: string;
    name: string;
    reason: string;
}

export class WhiteProcessModel extends BaseEntityModel {

    _id: string;
    name: string;
    reason: string;
}


<div id="userProfile" class="page-layout carded  fullwidth" fxLayout="column">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <!-- HEADER -->
    <div class="header accent p-24 h-70" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">Setari cont</span>
            </div>
            <div class="h1 mt-16">Setari</div>
        </div>
    </div>
    <!-- / HEADER -->

    <div class="content p-24">
        <div class="mat-elevation-z8">
            <div style="max-width: 100%!important;">
                <mat-card fxFlex="100" class="mt-10">

                    <mat-card-content>

                        <div fxFlex="100" class="p-24">
                            <mat-card-title>
                                <span> Profil utilizator </span>
                            </mat-card-title>

                            <form [formGroup]="userForm" style="max-width: 100%!important;">
                                <div fxLayout fxLayoutWrap fxLayoutGap="25px">
                                    <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
                                        <mat-label>Email</mat-label>
                                        <input matInput formControlName="email">
                                        <mat-icon matSuffix class="disabled-text">email_circle</mat-icon>
                                        <mat-error *ngIf="userForm.get('email').errors?.serverError">{{ userForm.get('email').errors?.serverError }}</mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
                                        <mat-label>Nume</mat-label>
                                        <input matInput formControlName="name">
                                        <mat-icon matSuffix class="disabled-text">account_circle</mat-icon>
                                        <mat-error *ngIf="userForm.get('name').errors?.serverError">{{ userForm.get('name').errors?.serverError }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout fxLayoutWrap fxLayoutGap="25px">
                                    <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
                                        <mat-label>Parola</mat-label>
                                        <input matInput type="password" formControlName="password">
                                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                        <mat-error *ngIf="userForm.get('password').errors?.serverError">{{ userForm.get('password').errors?.serverError }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
                                        <mat-label>Parola (Confirmare)</mat-label>
                                        <input matInput type="password" formControlName="password_confirmation">
                                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                        <mat-error *ngIf="userForm.get('password_confirmation').hasError('required')">
                                            Confirmarea parolei este obligatorie
                                        </mat-error>
                                        <mat-error *ngIf="!userForm.get('password_confirmation').hasError('required') && userForm.get('password_confirmation').hasError('passwordsNotMatching')">
                                            Parola nu este identica cu cea de mai sus
                                        </mat-error>
                                        <mat-error *ngIf="userForm.get('password_confirmation').errors?.serverError">{{ userForm.get('password_confirmation').errors?.serverError }}</mat-error>
                                    </mat-form-field>
                                </div>

                            </form>
                        </div>

                    </mat-card-content>

                    <mat-card-actions style="text-align: right; padding: 10px">
                        <button mat-raised-button color="accent" [disabled]="userForm.invalid" (click)="onSaveProfile()" >Salveaza</button>
                    </mat-card-actions>

                </mat-card>
            </div>
        </div>
    </div>

</div>

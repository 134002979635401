<h1 mat-dialog-title *ngIf="requestedUserUid">Modificare utilizator</h1>
<h1 mat-dialog-title *ngIf="!requestedUserUid">Adaugare utilizator</h1>
<div mat-dialog-content>
    <div fxFlex="100" class="p-24">
      <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
      <form [formGroup]="form" style="max-width: 100%!important;">
        <div fxLayout fxLayoutWrap fxLayoutGap="25px">
          <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
            <mat-icon matSuffix class="disabled-text">email_circle</mat-icon>
            <mat-error *ngIf="form.get('email').errors?.serverError">{{ form.get('email').errors?.serverError }}
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
            <mat-label>Nume</mat-label>
            <input matInput formControlName="name">
            <mat-icon matSuffix class="disabled-text">account_circle</mat-icon>
            <mat-error *ngIf="form.get('name').errors?.serverError">{{ form.get('name').errors?.serverError }}
            </mat-error>
          </mat-form-field>

      
        </div>
        <div fxLayout fxLayoutWrap fxLayoutGap="25px">
          <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
            <mat-label>Parola</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error *ngIf="form.get('password').errors?.serverError">{{
              form.get('password').errors?.serverError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" fxFlex="50" class="pr-4">
            <mat-label>Parola (Confirmare)</mat-label>
            <input matInput type="password" formControlName="password_confirmation">
            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
            <mat-error *ngIf="form.get('password_confirmation').hasError('required')">
              Confirmarea parolei este obligatorie
            </mat-error>
            <mat-error
                    *ngIf="!form.get('password_confirmation').hasError('required') && form.get('password_confirmation').hasError('passwordsNotMatching')">
              Parola nu este identica cu cea de mai sus
            </mat-error>
            <mat-error *ngIf="form.get('password_confirmation').errors?.serverError">{{
              form.get('password_confirmation').errors?.serverError }}</mat-error>
          </mat-form-field>
      
      
        </div>
        <div fxLayout fxLayoutWrap fxLayoutGap="25px">
          <section class="checkbox-section">
            <mat-checkbox class="checkbox-margin" formControlName="isActive">Activ
            </mat-checkbox>
          </section>
        </div>
      </form>
    </div>
</div>
<div mat-dialog-actions align="end" style="margin: 5px">
  <button mat-raised-button color="accent" [disabled]="form.invalid" (click)="onSave()" cdkFocusInitial>Salveaza</button>
</div>

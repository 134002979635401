<div id="userProfile" class="page-layout carded  fullwidth" fxLayout="column">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
  <!-- HEADER -->
  <div class="header accent p-24 h-70" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">home</mat-icon>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">Log-uri</span>
      </div>
      <div class="h1 mt-16" *ngIf="logs">Loguri de sistem din data: <b>{{logs.date}}</b></div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="content p-24">

    <div class="mat-elevation-z8" *ngIf="showSearch">
      <label>Select User Role</label>
      <mat-form-field>
        <mat-select #roleName="ngModel" [(ngModel)]="selectedOption" name="userRole">
          <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'"
                                 [noEntriesFoundLabel]="'Not found'" name="search"></ngx-mat-select-search>
          <mat-option *ngFor="let role of list | stringFilterBy:searchCtrl:'name' " [value]="role.name">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel *ngFor="let item of logs.logs | keyvalue"  [expanded]="step === item.key" (opened)="setStep( item.key)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> <mat-icon>{{getIcon(item.value.type)}} </mat-icon> &nbsp; &nbsp; {{item.value.timestamp}} </mat-panel-title>
            <mat-panel-description style="text-align: right; display: inline!important;">  {{item.value.type}} </mat-panel-description>
          </mat-expansion-panel-header>


          <div matRipple [matRippleTrigger]="trigger" class="my-ripple-container">
            <p>Data: <b>{{item.value.timestamp}}</b></p>
            <p>Tip: <b>{{item.value.type}}</b></p>
            <p>{{item.value.message}}</p>
          </div>

          <div #trigger></div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</div>


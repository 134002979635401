import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ValidationMessageService} from '../../../../shared/services/validation-message-handle';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../../../../auth/models/user.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {DeleteDialogComponent} from '../../../../shared/components/delete-dialog/delete-dialog.component';
import {UserService} from '../../../../auth/services/user.service';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {TableCommunicationService} from '../../../../shared/services/table-communication.service';
import {UsersAddEditComponent} from '../../users-add-edit/users-add-edit.component';
import {GridComponent} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-users-table-actions-template',
  templateUrl: './users-table-actions-template.component.html',
  styleUrls: ['./users-table-actions-template.component.css']
})
export class UsersTableActionsTemplateComponent implements OnInit, OnDestroy {

  public userModel: UserModel;
  public loading = false;
  public table: GridComponent;

  constructor(
      private dialog: MatDialog,
      private route: ActivatedRoute,
      private _formBuilder: FormBuilder,
      private validationMessageService: ValidationMessageService,
      private toastr: ToastrService,
      private router: Router,
      private userService: UserService,
      private tableCommunicationService: TableCommunicationService,
  ) {
  }

  ngOnInit(): void
  {

  }

  ngOnDestroy(): void
  {

  }

  setParams(user: UserModel): void
  {
    this.userModel = user;
  }

  edit(): void
  {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'dialog-class';
    dialogConfig.width = '80vw';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialog = this.dialog.open(UsersAddEditComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      data: {
        requestedUid: null,
        runAsModal: true,
        uid: this.userModel.uid
      }
    });


    dialog.afterClosed().subscribe(data => {
      this.table.refresh();
    });
  }

  delete(): void
  {

    const dialog = this.dialog.open(DeleteDialogComponent);

    dialog.afterClosed().subscribe(result => {

      if (result === true){

        this.loading = true;
        this.userService.delete(this.userModel)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
              this.toastr.success('Succes!', 'Stergerea a fost facuta cu succes!');
              this.table.refresh();

              dialog.close();
            }, err => {
              if (err instanceof HttpErrorResponse) {
                this.toastr.error(err.message);
              }
            });
      }
    });
  }

  restore(): void {
    this.loading = true;
    this.userService.restore(this.userModel)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response) => {

          this.toastr.success('Succes!', 'Utilizatorul a fost activata cu succes!');
          this.tableCommunicationService.reload();

        }, err => {
          if (err instanceof HttpErrorResponse) {
            this.toastr.error(err.message);
          }
        });
  }

  setTableReference(table: GridComponent): void
  {
    this.table = table;
  }
}

<div id="dashboard-analytics" class="page-layout blank">


    <div class="content">
        <div class="left mr-lg-32">

        </div>
    
    </div>

</div>

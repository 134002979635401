<div id="nomenclatures.users.list" class="page-layout carded  fullwidth" fxLayout="column">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="header accent p-24 h-70" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">home</mat-icon>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">Setari</span>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">Setari generale</span>
      </div>
      <div class="h1 mt-16" style="width: 100%">
        Setari generale
      </div>
    </div>
  </div>

  <div class="content p-24">
    <div class="mat-elevation-z8">
      <p>Config here!</p>
    </div>
  </div>

</div>

<div id="forgot-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Freya Data Analyzer
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
         Te rugam sa introduci adresa de email al contului tau si vei primii un email cu instructinui de resetare in cel mai scurt timp posibil.
        </div>

    </div>

    <div id="forgot-password-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/logo.png">
        </div>



        <div id="forgot-password-form">


            <div class="title">Resetare parola</div>

            <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">Email-ul este obligatoriu</mat-error>
                    <mat-error *ngIf="!forgotPasswordForm.get('email').hasError('required') && forgotPasswordForm.get('email').hasError('email')">
                        Te rugam sa introduci o adresa de email valida
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid" (click)="sendEmail()">
                    Reseteaza parola
                </button>

            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Inapoi la autentificare</a>
            </div>

        </div>
    </div>
</div>

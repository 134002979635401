import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';

import {DatePipe, DecimalPipe} from '@angular/common';
import {NomenclaturesModule} from './nomenclatures/nomenclatures.module';
import {AuthGuardService} from './guards/auth-guard.service';
import {MatDialogModule} from '@angular/material';
import {SettingsModule} from './settings/settings.module';

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: '/dashboard',
        canActivate: [AuthGuardService],
    },

];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        MatDialogModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AuthModule,
        HomeModule,
        NomenclaturesModule,
        SettingsModule,
    ],
    providers: [
        DatePipe, DecimalPipe
    ],
    exports: [
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule
{

}

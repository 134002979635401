import {Component, OnInit, ViewChild} from '@angular/core';
import {Query} from '@syncfusion/ej2-data';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {UserService} from '../../../auth/services/user.service';
import {Router} from '@angular/router';
import {
  UsersTableActionsTemplateComponent
} from '../../users/users-list/users-table-actions-template/users-table-actions-template.component';
import {UsersAddEditComponent} from '../../users/users-add-edit/users-add-edit.component';
import { SyncfusionDataGridComponent } from 'app/shared/components/syncfusion/syncfusion-data-grid/syncfusion-data-grid.component';
import {
  ProcessListActionTemplateComponent
} from './process-list-action-template/process-list-action-template.component';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.css']
})
export class ProcessListComponent implements OnInit {

  public loading: false;
  public tablePath: string;
  public tableActionComponent = null;
  public tableExpandComponent = null;
  public query: Query;

  @ViewChild('grid', {static: false}) grid: SyncfusionDataGridComponent;

  constructor(public dialog: MatDialog, private router: Router) {

  }

  ngOnInit(): void {
    this.refreshtable();
    this.tableActionComponent = ProcessListActionTemplateComponent;
  }

  refreshtable(): void {
    this.tablePath = 'admin/process/index';
    this.query = new Query();
  }

  applyFilter(data: any): void {

    this.query = new Query();
    console.log(typeof data);

    Object.entries(data).map(item => {
      this.query.addParams(item[0] as string, item[1] as string);
    });

    this.grid.query = this.query;
  }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '../models/user.model';
import {ApiResponseModel} from '../../shared/entities/api-response.model';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {confirmPasswordValidator} from '../reset-password/reset-password.component';
import { MatSort} from '@angular/material';
import {UserProfileModel} from '../models/user-profile.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ValidationMessageService} from '../../shared/services/validation-message-handle';
import {HttpApiService} from '../../shared/services/http-api.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})

export class UserProfileComponent implements OnInit, OnDestroy {

    public loading = false;
    public userForm: FormGroup;
    public userProfile: UserProfileModel;

    @ViewChild(MatSort, {static: true}) sort: MatSort;


    constructor(
        private _formBuilder: FormBuilder,
        private userService: UserService,
        private validationMessageService: ValidationMessageService,
        private toastr: ToastrService,
        private httpApiService: HttpApiService,
    ){

    }

    ngOnInit(): void
    {


        // Reactive Form
        this.userForm = this._formBuilder.group({
            name : ['', Validators.required],
            email   : [{ value: '', disabled: true}, Validators.required],
            password  : [''],
            password_confirmation   : ['', confirmPasswordValidator],
        });


        this.getProfile();

    }

    getProfile(): void {
        this.loading = true;
        this.userService.getUserProfile()
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: ApiResponseModel) => {

                const user: UserModel = new UserModel().map(response.payload);

                this.userProfile = response.payload;

                this.userForm.patchValue({
                    name: user.name,
                    email: user.email,
                    password: null,
                    password_confirmation: null,
                });

            });
    }


    ngOnDestroy(): void
    {

    }

    onSaveProfile(): void
    {

        this.loading = true;

        const userProfileModel =  new UserModel().map(this.userForm.getRawValue());

        this.userService.updateUserProfile(userProfileModel)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: ApiResponseModel) => {
                this.userProfile = response.payload;
                if(response.status === true){
                    this.toastr.success('Succes!', 'Utilizatorul a fost creeat cu succes!');
                }
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.userForm, 'user.' , false);
                }
            });

    }

}


<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-xs>


        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            Freya Data Analyzer
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Pentru a finaliza resetarea contului, te rugam sa completezi campurile din dreapta.
        </div>

    </div>

    <div id="reset-password-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">


        <div class="logo">
            <img src="assets/images/logos/logo.png">
        </div>


        <div id="reset-password-form">
            <div class="title">Resetare parola</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Parola</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>Parola este obligatorie</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Parola (Confirmare)</mat-label>
                    <input matInput type="password" formControlName="password_confirmation">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('password_confirmation').hasError('required')">
                        Confirmarea parolei este obligatorie
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('password_confirmation').hasError('required') &&
                                       resetPasswordForm.get('password_confirmation').hasError('passwordsNotMatching')">
                        Parola nu este identica cu cea de mai sus
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid || this.passwordResetToken == null || this.passwordResetEmail == null" (click)="resetPassword()">Resetare
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Inapoi la autentificare</a>
            </div>

        </div>
    </div>
</div>

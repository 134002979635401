import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Injectable} from '@angular/core';
import {HttpApiService} from '../../shared/services/http-api.service';

import {AuthRequestModel, AuthResponseModel} from '../models/auth.model';
import {Router} from '@angular/router';
import {RecoverPasswordRequestModel, ResetPasswordRequestModel} from '../models/recover-password-request.model';
import {Observable} from 'rxjs';
import {UserModel} from '../models/user.model';
import {ApiResponseModel} from '../../shared/entities/api-response.model';

import {CompanyModel} from '../models/company.model';


@Injectable({
    providedIn: 'root'
})

export class UserService {

    public tokenLocalStorageKey: string = 'auth-token';
    public redirectUrl: string = '/';

    constructor(
        public httpApiService: HttpApiService,
        private router: Router,
    ) {

    }

    public checkAuth(): boolean {
        const token = localStorage.getItem(this.tokenLocalStorageKey);

        if (token) {
            // TODO Check if it`s a valid JWT token
            return true;
        } else {
            return false;
        }

    }

    public isAdmin(): boolean {
        const user = this.getUserFromLocalStorage();
        return user.isAdmin;
    }

    public setUserDetails(userDetails: AuthResponseModel): void {
        localStorage.setItem(this.tokenLocalStorageKey, userDetails.access_token);
        localStorage.setItem('user_name', userDetails.user.name);
        localStorage.setItem('auth_expire_in', userDetails.expires_in.toString());
        localStorage.setItem('auth_expire_at', userDetails.expire_at.toString());
        localStorage.setItem('user', JSON.stringify(userDetails.user));
        localStorage.setItem('company', JSON.stringify(userDetails.user.company));
    }

    public getUserCompanyFromLocalStorage(): CompanyModel
    {
        return new CompanyModel().map(JSON.parse(localStorage.getItem('company')));
    }

    public getUserFromLocalStorage(): UserModel
    {
        return new UserModel().map(JSON.parse(localStorage.getItem('user')));
    }

    public getUserNameFromLocalStorage(): string
    {
        return localStorage.getItem('user_name');
    }

    public setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    public logout(): void {
        localStorage.removeItem(this.tokenLocalStorageKey);
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_type');
        localStorage.removeItem('auth_expire_in');
        localStorage.removeItem('auth_expire_at');
        localStorage.removeItem('user');
        localStorage.removeItem('company');

        this.router.navigateByUrl('/auth/login');
    }

    public login(authModel: AuthRequestModel): Observable<any> {
        return this.httpApiService.httpPost('auth/login', authModel);
    }

    public register(registerModel): Observable<any> {
        return this.httpApiService.httpPost('auth/register', registerModel);
    }

    public recoverPassword(recoverPasswordRequestModel: RecoverPasswordRequestModel): void {

        this.httpApiService.httpPost('auth/forgot-password', recoverPasswordRequestModel).subscribe((response) => {
            if (response.status === true) {

                Swal.fire({
                    title: 'Recuperare parola',
                    html: 'Daca adresa de email exista in sistem, veti primii un link pentru resetarea parolei.',
                    icon: 'success',

                    onClose: () => {
                        this.router.navigateByUrl('/auth/login?email=' + recoverPasswordRequestModel.email);
                    }
                });


            }
        });

    }

    public resetPassword(resetPasswordModel: ResetPasswordRequestModel): void {
        this.httpApiService.httpPost('auth/reset-password', resetPasswordModel).subscribe((response) => {
            if (response.status === true) {
                Swal.fire({
                    title: 'Resetare parola',
                    html: 'Parola a fost resetata cu succes. Te poti autentifica in platforma folosind noua parola.',
                    icon: 'success',

                    onClose: () => {
                        this.router.navigateByUrl('/auth/login');
                    }
                });
            }
        });
    }

    public getUserProfile(uid?: string): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('user/edit', {uid: uid});
    }

    public createUserProfile(userProfile: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/store', userProfile);
    }

    public updateUserProfile(userProfile: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/update', userProfile);
    }

    public getList(params = null): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('user/getList', params);
    }


    getTable(params = null): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/index', params);
    }

    create(data: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/store', data);
    }

    update(data: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/update', data);
    }

    delete(data: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpDelete('user/destroy', data);
    }

    restore(data: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('user/restore', data);
    }

    getFile(data: UserModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpDelete('user/destroy', data);
    }

}



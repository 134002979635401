import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ApiResponseModel } from '../entities/api-response.model';
import {Observable, throwError} from 'rxjs';
import {HttpRequestOptionsModel} from '../entities/http-request-options.model';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {ok} from 'assert';
import {Router} from '@angular/router';
import {serialize} from 'object-to-formdata';

@Injectable({
    providedIn: 'root'
})
export class HttpApiService {

    public apiURL = 'https://api-isf-process.stdev.ro/api/';

 
    constructor(private http: HttpClient, private router: Router) {

    }

    public getAuthToken(): string {
        return 'Bearer ' + localStorage.getItem('auth-token');
    }

    public getHeaders(type: string  = 'application/json', asList: boolean = false): any {

        const headers = {
            'Authorization': this.getAuthToken(),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        };

        if (type) {
            headers['Content-Type'] = type;
        }
        /*
        if(asList === true){
            return headers;
        }
        */


        return new HttpHeaders(headers);
    }

    public objectToFormData(obj: any, form?: FormData, namespace?: any): FormData {
        const options = {
            /**
             * include array indices in FormData keys
             * defaults to false
             */
            indices: true,

            /**
             * treat null values like undefined values and ignore them
             * defaults to false
             */
            nullsAsUndefineds: false,

            /**
             * convert true or false to 1 or 0 respectively
             * defaults to false
             */
            booleansAsIntegers: false,

            /**
             * store arrays even if they're empty
             * defaults to false
             */
            allowEmptyArrays: true,
        };
        return serialize(obj, options);
    }


    public getUrl(path: string): string {
        return this.apiURL + path;
    }

    public setParameters(data: any): any {

        const result = [];

        if (!data) {
            return result;
        }

        const _this = this;

        Object.keys(data).map( key =>  {

            if (typeof data[key] === 'object') {
                result[key] = _this.setParameters(data[key]);
            } else {
                result[key] = (data[key]) ? data[key] : '';
            }
        });

        return result;
    }

    public httpGet(path: string, params?: any): Observable<any> {

        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();

        httpOptions.headers = this.getHeaders();
        httpOptions.params = this.setParameters(params);


        return this.http.get(url, httpOptions)
            .pipe(
                tap(response => {
                    // For errors with http code 200
                    // @ts-ignore
                    if (response.status === false) {
                        // @ts-ignore
                        Swal.fire('Eroare', response.message, 'error');
                    }

                }),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),
                finalize(() => {

                })
            );
    }

    public httpPost(path: string, body?: any): Observable<any> {

        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders(null);

        return this.http.post(url, this.objectToFormData(body), httpOptions)
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );

    }

    public httpPut(path: string, body?: any): Observable<any> {

        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders();

        return this.http.put(url, body, httpOptions)
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );

    }

    public httpDelete(path: string, body?: any): Observable<any> {

        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders();
        httpOptions.params = this.setParameters(body);

        return this.http.delete(url, httpOptions)
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );

    }

    public postFile(file: File, entity: string, type: string, uid: string, path: string, fd: FormData=null): Observable<any> {

        let formData = new FormData();
        if(fd === null){
            formData.append('file', file);
            formData.append('type', type);
            formData.append('entity', entity);
            formData.append('uid', uid);
        }
        else{
            formData = fd;
        }

        return this.http.post<any>(this.getUrl(path), formData, {headers: this.getHeaders(null)})
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );
    }
    public postFiles(path: string, files: any, params: Array<any>): Observable<any> {

        const formData = new FormData();

        files.commercial.forEach((file, index) => {
            const key = 'commercial[' + index.toString() + ']';
            formData.append(key, file);
        });

        files.technical.forEach((file, index) => {
            const key = 'technical[' + index.toString() + ']';
            formData.append(key, file);
        });

        params.forEach((parameter) => {
            console.log(parameter);
            formData.append(parameter.name, parameter.value);
        });

        return this.http.post<any>(this.getUrl(path), formData, {headers: this.getHeaders(null)})
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );
    }

    public getFile(entity: string, type: string, uid: string, allFiles: boolean = true): Observable<any> {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('entity', entity);
        formData.append('uid', uid);
        // @ts-ignore
        formData.append('allFiles', allFiles.toString());

        return this.http.post<any>(this.getUrl('getFile'), formData)
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );
    }

    private handleError(exception): void {
        console.log(exception);

        if (exception.error && exception.error.message === 'auth.login.fail') {
            Swal.fire('Autentificare', 'Adresa de email sau parola nu este corecta. Va rugam sa incercati din nou.', 'error');
        }

        if (exception.status === 0) {
            Swal.fire('Eroare', 'Nu s-a putut realiza conexiunea cu serverul. Va rugam sa incercati din nou.', 'error');
        }

        if (exception.status === 511) {
            Swal.fire('Autentificare', 'Sesiunea dumneavoastra a expirat, va rugam sa va reautentificati!', 'error');
            localStorage.removeItem('auth-token');
            this.router.navigateByUrl('/login');
        }

        if (exception.status === 400) {
            Swal.fire('Eroare neasteptata', 'Va rugam sa mai incercati inca o data, iar daca eroarea persista, va rugam sa contactati administratorul.', 'error');
        }

    }

    public httpSocketPost(path: string, formData?: FormData): Observable<any> {
        console.log('Headers -> ', this.getHeaders(null));
        return this.http.post(path, formData, {headers: this.getHeaders(null)})
            .pipe(
                tap(
                    response => {
                        // For errors with http code 200
                        // @ts-ignore
                        if (response.status === false) {
                            // @ts-ignore
                            Swal.fire('Eroare', response.message, 'error');
                        }

                    }
                ),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {

                })
            );

    }

    public httpPostDownload(path: string, body?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders(null);
        httpOptions.responseType = 'blob';

        return this.http.post(url, this.objectToFormData(body), httpOptions);
    }

}


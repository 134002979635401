import {Component, OnInit, ViewChild} from '@angular/core';
import {ChartColorModel, ChartParametersModel} from '../../types/chart-parameters.model';
import {DashboardService} from '../../services/dashboard.service';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from '../../shared/entities/api-response.model';
import {CompanyModel} from '../../types/company.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public loading: any = false;
  public widgets = {
    widget7: {
      scheme : {
        domain: ['#4867d2', '#5c84f1', '#89a9f4']
      },
      devices: [
        {
          name  : 'Desktop',
          value : 92.8,
          change: -0.6
        },
        {
          name  : 'Mobile',
          value : 6.1,
          change: 0.7
        },
        {
          name  : 'Tablet',
          value : 1.1,
          change: 0.1
        }
      ]
    },
  };
  constructor(
      private dashboardService: DashboardService,
      private toastr: ToastrService,
      private router: Router
  ) {

  }

  ngOnInit(): void {
    //
  }

}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpApiService} from '../shared/services/http-api.service';
import {ApiResponseModel} from '../shared/entities/api-response.model';
import {ProcessModel} from '../types/process.model';

@Injectable({
    providedIn: 'root'
})

export class DashboardService {

    constructor(
        public httpApiService: HttpApiService,
        private router: Router,
    ) {

    }

    getStatistics(): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('dashboard/getStatistics');
    }

}



import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Form, FormGroup, ValidationErrors} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
    providedIn: 'root'
})

export class ValidationMessageService {

    public serverSideValidation(err: HttpErrorResponse, form: FormGroup, prefix = '', showOtherErrors: boolean = true): void
    {

        if (err.status === 422) {

            const errors: Array<any> = err.error.payload.errors;

            Object.keys(errors).forEach(propertyName => {

                const prop = propertyName.replace(prefix, '' );

                const formControl = form.get(prop);
                console.log(formControl);

                if (formControl) {
                    console.log('Error on field -> ', prop, ' -> message -> ', errors[propertyName]);
                    // activate the error message

                    let message = '';
                    
                    if ( Symbol.iterator in Object(errors[propertyName]) ){
                        // tslint:disable-next-line:only-arrow-functions typedef
                        errors[propertyName].forEach(function(value, index) {
                            message += value.replace(prefix, '' ) + '\r\n';
                        });
                    }else{
                        message =   errors[propertyName].replace(prefix, '' ) + '\r\n';
                    }


                    // @ts-ignore
                    formControl.setErrors({
                        serverError: message,
                    });
                    
                    

                } else {
                    if ( showOtherErrors === true ){
                        
                        let message = '';

                        // tslint:disable-next-line:only-arrow-functions typedef
                        errors[propertyName].forEach(function( value, index ) {
                            message += value;
                        });

                        Swal.fire('Eroare validare: ' + prop.toUpperCase(), message , 'error');
                    }
                }

            });
        }
    }

    getFormValidationErrors(form: FormGroup): void
    {
        Object.keys(form.controls).forEach(key => {

            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }
}

